<template>
  <div>
    <div class="accueil_content">
      <div class="accueil_content_inside">

        <div class="columns is-centered" v-if="popupSalonExistePlusVisible">
          <div class="column is-4-desktop is-7-tablet is-12-mobile">
            <article class="message is-warning">
              <div class="message-header">
                <p style="font-weight: normal;">{{ $t('sketchy.notification.salon_existe_plus') }}</p>
                <button class="delete" aria-label="delete" @click="popupSalonExistePlusVisible = false"></button>
              </div>
            </article>
          </div>
        </div>

        <div class="columns is-centered" v-if="popupSalonPlein">
          <div class="column is-4-desktop is-7-tablet is-12-mobile">
            <article class="message is-warning">
              <div class="message-header">
                <p style="font-weight: normal;">{{ $t('sketchy.notification.salon_plein') }}</p>
                <button class="delete" aria-label="delete" @click="popupSalonPlein = false"></button>
              </div>
            </article>
          </div>
        </div>

        <NotificationRejoin @rejoin="revenirEnJeu" ref="notificationRejoin"/>

        <div class="columns is-centered"
             v-if="notificationYouHaveBeenKicked !== null && notificationYouHaveBeenKicked !== ''">
          <div class="column is-4-desktop is-7-tablet is-12-mobile">
            <article class="message is-warning">
              <div class="message-header">
                <p style="font-weight: normal;">
                  {{ $t('sketchy.notification.kicked', [notificationYouHaveBeenKicked]) }}
                </p>
                <button class="delete" aria-label="delete" @click="notificationYouHaveBeenKicked = null"></button>
              </div>
            </article>
          </div>
        </div>

        <div class="columns is-marginless is-fluid commun" v-if="ws_connected">
          <div class="column is-two-thirds has-text-centered">
            <h1 class="titres">{{ $t('sketchy.page_accueil.rejoindre_partie') }}</h1>

            <div class="join">
              <div
                class="salon-content"
                :class="{
                  'frosted-background-static': $isSafari,
                  'frosted-background': !$isSafari,
                }"
              >
                <div class="liste-salons" v-if="Object.keys(salons).length === 0">
                  {{ $t('sketchy.page_accueil.aucune_partie') }}
                </div>
                <div v-for="salon in salons" class="liste-salons">

                  <div class="salon">
                    <div class="is-pulled-left nom-partie">
                      <span v-if="salon.public"
                            class="green-dot">●</span>
                      <IconeCadenas class="cadenas" v-else/>

                      <span class="texte-nom">{{ salon.nom }}</span>
                    </div>
                    <div class="is-pulled-right">
                      <BoutonPlay class="join-game" @click="joinGame(salon.id)"/>
                    </div>
                    <div style="clear: both;"></div>

                    <div class="is-pulled-left nb-joueurs">
                      <IconeGens class="icone"/>
                      <span class="compteur">{{ salon.nbJoueur }}/{{ salon.maxJoueur }}</span>
                    </div>
                    <div class="is-pulled-right host">{{ salon.hote.substr(0, 20) }}
                      <span v-if="salon.langue === 'en'">
                        <DrapeauUsaUk class="drapeau"/>
                      </span>
                      <span v-if="salon.langue === 'fr'">
                        <DrapeauFr class="drapeau"/>
                      </span>
                    </div>


                    <div style="clear: both;"></div>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third has-text-centered">
            <div style="max-width: 300px;display: inline-block;">
              <h1 class="titres">{{ $t('sketchy.page_accueil.nouvelle_partie') }}</h1>

              <div
                class="creation"
                :class="{
                  'frosted-background-static': $isSafari,
                  'frosted-background': !$isSafari,
                }"
              >

                <div v-if="nomSalonTropGrand" style="font-size: 20px; margin-top: 20px">
                  <span class="tag is-danger is-medium">{{
                      $t('sketchy.page_accueil.nom_salon_long_$d', [$sketchy_nom_salon_max])
                    }}</span>
                </div>
                <div class="margin-top">
                  <label>{{ $t('sketchy.page_accueil.salon_nom') }}</label>
                  <br/>
                  <input
                    type="text"
                    class="input is-small"
                    v-model="nom_salon"
                    :maxlength="$sketchy_nom_salon_max"
                    @keyup.enter="createGame"
                  />
                </div>

                <div v-if="nomJoueurTropGrand" style="font-size: 20px; margin-top: 20px">
                  <span class="tag is-danger is-medium">{{
                      $t('sketchy.page_jeu.etat_0.pseudo_long_$d', [$sketchy_pseudo_max])
                    }}</span>
                </div>
                <div class="margin-top">
                  <label>{{ $t('sketchy.global.choix_pseudo') }}</label>
                  <br/>

                  <input
                    type="text"
                    class="input is-small"
                    v-model="nom_joueur"
                    :maxlength="$sketchy_pseudo_max"
                    @keyup.enter="createGame"
                  />
                </div>

                <div class="control margin-top">
                  <label class="radio" style="margin-right: 8px;">
                    <input type="radio" name="answer" :value="true" v-model="public">
                    {{ $t('sketchy.page_accueil.salon_public') }}
                  </label>
                  <label class="radio">
                    <input type="radio" name="answer" :value="false" v-model="public">
                    {{ $t('sketchy.page_accueil.salon_prive') }}
                  </label>
                </div>

                <div class="margin-top" v-if="public === false">
                  <label>{{ $t('sketchy.global.password') }}</label>
                  <br/>
                  <input
                    type="text"
                    ref="passwordInput"
                    class="input is-small"
                    v-model="password"
                    :maxlength="$sketchy_pass_max"
                    @keyup.enter="createGame"
                  />
                </div>

                <div class="margin-top">
                  <BoutonPlus @click="createGame"/>
                </div>
              </div>

              <NotificationChangelog style="margin-top: 8px;width: 300px;display: inline-block;"/>
            </div>
          </div>
        </div>

        <div class="has-text-centered" v-else>
          <button class="button is-large is-static is-loading"></button>
        </div>

      </div>
    </div>

    <NotificationDiscord
      v-if="!$session.data._notif_discord_seen"
      class="accueil_footer"
      @close="$session._notif_discord_seen = true"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "~@/resources/style/helpers/_nice_scrollbar";
@import '~@/resources/libraries/bulma-0.9.0/sass/utilities/_all.sass';

.accueil_content {
  min-height: calc(100% - 125px);

  .accueil_content_inside {
    padding: 20px;
    padding-bottom: 50px;

    .accueil_footer {
      height: 50px;
      margin-top: -50px;
      background-color: red;
      width: 100%;
    }
  }
}

.commun {
  margin-top: 25px;
  font-family: cabin_sketch_regular;
}

.titres {
  font-size: 40px;
  font-family: cabin_sketch_bold
}

.input {
  width: 230px;
}

.join {
  display: inline-block;

}

.margin-top {
  margin-top: 15px;
}

.creation {
  display: inline-block;
  width: 300px;
  border: 1px rgba(0, 0, 0, 0.15) solid;
  border-radius: 10px;
}

.salon-content {
  border-radius: 12px;
  border: rgba(0, 0, 0, 0.15) 1px solid;
  overflow-y: auto;
  max-height: calc(100vh - 300px);
  @include nice-scrollbar;
}

.liste-salons {

  //LARGE
  width: 800px;
  font-size: 20px;

  //MEDIUM
  @media only screen and (max-width: 1200px) {
    width: 600px;
  }

  //SMALL
  @media only screen and (max-width: 900px) {
    width: 400px;
  }

  //PHONES
  @media only screen and (max-width: 600px) {
    width: 300px;
  }

  &:not(:first-of-type) {
    border-top: 1px rgba(0, 0, 0, 0.12) solid;
  }

  .salon {

    //padding: 10px;
    padding: 12px 12px 0;

    .green-dot {
      width: 20px;
      font-size: 25px;
      display: inline-block;
      color: #00b89c;
      margin-right: 14px;

      @include mobile {
        margin-right: 5px;
      }
    }

    .nom-partie {
      max-width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-family: cabin_sketch_regular;
      color: #444;
      @include mobile {
        max-width: 80%;
      }

      .cadenas {
        display: inline-block;
        vertical-align: top;
        height: 24px;
        width: 24px;
        margin-right: 12px;
        @include mobile {
          margin-right: 5px;
        }
      }

      .texte-nom {

      }

      @include mobile {
        font-size: 15px;
      }
    }

    .nb-joueurs {
      font-family: cabin_sketch_regular;

      vertical-align: top;

      .icone {
        display: inline-block;
        vertical-align: top;
        height: 24px;
        width: 26px;
        margin-right: 9px;
      }

      .compteur {
        display: inline-block;
        vertical-align: top;
        height: 23px;
        margin-bottom: 10px;
        font-size: 19px;
        @include mobile {
          font-size: 15px;
        }
      }
    }

    .host-drapeau {
      font-family: cabin_sketch_regular;
      color: rgba(0, 0, 0, 0.5);
      font-size: 17px;
      line-height: 30px;
    }

    .drapeau {
      width: auto;
      height: 18px;
      margin-right: 20px;
      margin-left: 5px;
      opacity: 0.65;
      @include mobile {
        margin-right: 0;
      }
    }

    .join-game {
      height: 35px;
      width: auto;
      margin-right: 20px;
      margin-left: 5px;
      @include mobile {
        margin-right: 0;
      }
    }

    .host {
      @include mobile {
        font-size: 15px;
      }
    }
  }

}

</style>

<script>
import accueil_ws from '@/resources/js/custom/accueil_ws';

export default {
  name: 'Accueil',

  data() {
    return {
      ws_connected: false,

      nom_salon: '',
      nom_salon_default: '',

      nom_joueur: '',
      nom_joueur_default: '',

      password: null,
      public: true,
      salons: {},

      popupSalonExistePlusVisible: false,
      popupSalonPlein: false,

      notificationYouHaveBeenKicked: null,
    };
  },

  created() {
    this.connect();

    if ('_sketchy_err_salon_existe_plus' in window && window._sketchy_err_salon_existe_plus === true) {
      window._sketchy_err_salon_existe_plus = false;
      delete window._sketchy_err_salon_existe_plus;

      this.popupSalonExistePlusVisible = true;
    }

    if ('_sketchy_err_salon_plein' in window && window._sketchy_err_salon_plein === true) {
      window._sketchy_err_salon_plein = false;
      delete window._sketchy_err_salon_plein;

      this.popupSalonPlein = true;
    }

    if ('_sketchy_notif_you_have_been_kicked' in window && window._sketchy_notif_you_have_been_kicked !== undefined && window._sketchy_notif_you_have_been_kicked !== null && window._sketchy_notif_you_have_been_kicked !== '') {
      this.notificationYouHaveBeenKicked = window._sketchy_notif_you_have_been_kicked;
      delete window._sketchy_notif_you_have_been_kicked;
    }
  },

  mounted() {
    if ('_sketchy_open_tuto' in window && window._sketchy_open_tuto === true) {
      delete window._sketchy_open_tuto;
      //this.$session._tuto_first_time_visit = false; // ??
      this.$nextTick(() => {
        this.$bus.$emit('open_tuto');
      });
    }
  },

  beforeDestroy() {
    accueil_ws.close();
  },

  computed: {
    nomSalonTropGrand() {
      return this.nom_salon.length > this.$sketchy_nom_salon_max;
    },
    nomJoueurTropGrand() {
      return this.nom_joueur.length > this.$sketchy_pseudo_max;
    },
    formValid() {
      return this.nom_salon != null && this.nom_salon.trim().length > 0 && !this.nomSalonTropGrand && !this.nomJoueurTropGrand && this.nom_joueur != null && this.nom_joueur.trim().length > 0
    }
  },

  methods: {
    revenirEnJeu() {
      const idSalon = this.$session.data._last_salon;
      window._sketchy_pseudo = this.$session.data._last_pseudo;
      window._sketchy_password = this.$session.data._last_password;

      this.$refs.notificationRejoin.clear();

      this.$router.push({name: 'Salon', params: {salon: idSalon}})
    },

    connect() {
      accueil_ws.connect(this.$url_serveur_ws + '/accueil', this.onMessage, () => {
        this.salons = {};
        this.ws_connected = true;
      }, () => {
        this.ws_connected = false;
      });
    },

    onMessage(msg_type, msg_data) {
      console.log('Réception message =>', msg_type, msg_data);

      switch (msg_type) {
        case 'salon_cree':
          if (msg_data) {
            window._sketchy_pseudo = this.nom_joueur;
            window._sketchy_password = this.password;
            this.$router.push({name: 'Salon', params: {salon: msg_data}})
          }
          break;

        case 'nouveau_salon':
          this.$set(this.salons, msg_data.id, msg_data);
          break;

        case 'salon_update':
          const idSalon = msg_data.id;
          if (idSalon in this.salons) {
            if ('nbJoueur' in msg_data)
              this.salons[idSalon].nbJoueur = msg_data.nbJoueur;

            if ('hote' in msg_data)
              this.salons[idSalon].hote = msg_data.hote;

            if ('maxJoueur' in msg_data)
              this.salons[idSalon].maxJoueur = msg_data.maxJoueur;
          }
          break;

        case 'salon_delete':
          if (msg_data in this.salons)
            this.$delete(this.salons, msg_data);

          if (this.$session._last_salon !== undefined)
            if (this.$session._last_salon === msg_data)
              this.$refs.notificationRejoin.clear();
      }
    },

    joinGame(id) {
      //console.log(id)

      window._sketchy_pseudo = null;
      window._sketchy_password = null;
      this.$router.push({name: 'Salon', params: {salon: id}})
    },

    createGame() {
      //console.log(this.formValid)
      if (!this.formValid) return;

      this.sendMsg("accueil_creer_salon", {
        nom_salon: this.nom_salon,
        nom_joueur: this.nom_joueur,
        password: this.public ? '' : this.password,
        langue: this.$locale,
      });

      this._matomo_trackGoal(this.MTM_GOAL_GAME_NEW);
    },

    sendMsg(msg_type, msg_data) {
      accueil_ws.send(msg_type, msg_data);
    },
  },

  watch: {
    'public': function (v) {
      if (!v) {
        this.password = '';
        this.$nextTick(() => {
          this.$refs.passwordInput.focus();
        });
      }
    },

    '$locale': {
      handler: function () {
        if (this.nom_joueur_default === this.nom_joueur) {
          this.nom_joueur = this.$locale === 'fr' ?
            this.$jeans[this.randBetween(0, this.$jeans.length - 1)] :
            this.$jeans_en[this.randBetween(0, this.$jeans_en.length - 1)];
          this.nom_joueur_default = this.nom_joueur;
        }

        if (this.nom_salon === this.nom_salon_default) {
          this.$localeOk.then(() => {
            let curMessages = this.$root._i18n.getLocaleMessage(this.$root._i18n.locale);

            if (!('sketchy' in curMessages)) return;
            curMessages = curMessages.sketchy;

            if (!('page_accueil' in curMessages)) return;
            curMessages = curMessages.page_accueil;

            if (!('salon_random' in curMessages)) return;
            curMessages = curMessages.salon_random;

            if (!('prefixe' in curMessages)) return;
            const liste_prefixes = curMessages.prefixe;
            const index_prefixes = Object.keys(liste_prefixes);
            //console.log('liste_prefixes =', liste_prefixes);
            //console.log('index_prefixes =', index_prefixes);

            if (!('suffixe' in curMessages)) return;
            const liste_suffixes = curMessages.suffixe;
            const index_suffixes = Object.keys(liste_suffixes);
            //console.log('liste_suffixes =', liste_suffixes);
            //console.log('index_suffixes =', index_suffixes);

            let a = null;
            while (a === null) {
              const i = index_prefixes[this.randBetween(0, index_prefixes.length - 1)];
              a = liste_prefixes[i];
              if (a === undefined || a === null || a === false || a.trim() === '')
                a = null;
            }

            let b = null;
            while (b === null) {
              const i = index_suffixes[this.randBetween(0, index_suffixes.length - 1)];
              b = liste_suffixes[i];
              if (b === undefined || b === null || b === false || b.trim() === '')
                b = null;
            }

            //console.log('a =', a);
            //console.log('b =', b);

            this.nom_salon = this.$locale === 'fr' ? (a + ' ' + b) : (b + ' ' + a);
            this.nom_salon_default = this.nom_salon;
          });
        }
      },
      immediate: true,
    },
  },

  components: {
    DrapeauFr: () => import('@/resources/images/Icone_pays_fr'),
    DrapeauUsaUk: () => import('@/resources/images/Icone_pays_usa_uk'),

    NotificationRejoin: () => import('./components/global/nav/NotificationRejoin'),
    BoutonPlay: () => import('./components/global/boutons/BoutonPlay'),
    BoutonPlus: () => import('./components/global/boutons/BoutonPlus'),
    IconeCadenas: () => import('@/resources/images/IconeCadenas'),
    IconeGens: () => import('@/resources/images/IconeGens'),

    NotificationChangelog: () => import('@/views/components/global/nav/NotificationChangelog'),
    NotificationDiscord: () => import('@/views/components/global/nav/NotificationDiscord'),
  },
}
</script>
