export default {
    socket: null,
    url: null,
    onMessageCallback: null,
    onOpenCallback: null,
    onDisconnectCallback: null,
    mustTerminate: false,
    reconnectInterval: null,

    connect(url, onMessageCallback, onOpenCallback, onDisconnectCallback) {
        this.url = url;
        this.onMessageCallback = onMessageCallback;
        this.onOpenCallback = onOpenCallback;
        this.onDisconnectCallback = onDisconnectCallback;

        this._connect();
    },

    _connect() {
        if (this.socket !== null)
            this.reset();

        this.socket = new WebSocket(this.url);
        this.socket.onerror = this.onError.bind(this);
        this.socket.onopen = this.onOpen.bind(this);
        this.socket.onclose = this.onClose.bind(this);
        this.socket.onmessage = this.onMessage.bind(this);
    },

    onError(erreur) {
        console.log('onError =>', erreur);
        this.onDisconnectCallback();
        this._disconnect_and_clear();
    },

    onOpen(event) {
        console.log('onOpen =>', event);

        this.socket.send(JSON.stringify({
            msg_type: 'hello_sketchy!',
        }));

        this.onOpenCallback();
    },

    close() {
        console.info('CLOSE EXPLICIT CALLED');
        this.mustTerminate = true;

        if (this.socket !== null)
            this.socket.close();

        if (this.onDisconnectCallback !== null)
            this.onDisconnectCallback();

        this._disconnect_and_clear();
    },

    reset() {
        this.url = null;

        if (this.socket !== null) {
            this.socket.onerror = null;
            this.socket.onopen = null;
            this.socket.onclose = null;
            this.socket.onmessage = null;
            this.socket.close();
        }
        this.socket = null;

        this.onMessageCallback = null;
        this.onOpenCallback = null;
        this.onDisconnectCallback = null;
        this.mustTerminate = false;

        if (this.reconnectInterval !== null) {
            clearTimeout(this.reconnectInterval);
            this.reconnectInterval = null;
        }
    },

    onClose(event) {
        console.log('onClose =>', event);
        this._disconnect_and_clear();
        this.onDisconnectCallback();
    },

    onMessage(e) {
        //console.log('onMessage =>', e);
        const decodedMsg = JSON.parse(e.data);
        this.onMessageCallback(decodedMsg.msg_type, decodedMsg.msg_data);
    },

    send(msg_type, msg_data) {
        let msg = {
            msg_type: msg_type,
        };

        if (msg_data !== undefined) {
            if (msg_type === 'accueil_creer_salon' && typeof msg_data === 'object') {
                if ('nom_salon' in msg_data && typeof msg_data.nom_salon === 'string') {
                    if (msg_data.nom_salon.length > 50) {
                        msg_data.nom_salon = msg_data.nom_salon.substr(0, 50);
                    }
                }
            }

            msg.msg_data = msg_data;
        }
        this.socket.send(JSON.stringify(msg));
    },

    _disconnect_and_clear() {
        if (this.socket !== null) {
            this.socket.onerror = null;
            this.socket.onopen = null;
            this.socket.onclose = null;
            this.socket.onmessage = null;
            this.socket = null;
        }

        if (this.onDisconnectCallback !== null)
            this.onDisconnectCallback();

        console.log('disconnect and clear');
        if (!this.mustTerminate) {
            if (this.reconnectInterval !== null) {
                clearTimeout(this.reconnectInterval);
                this.reconnectInterval = null;
            }

            this.reconnectInterval = setTimeout(this._connect.bind(this), 600);
        }
    },

};
